<template>
  <div class="am-wrapper">
    <div class="am-header">
      <h2 class="am-header-title">{{ asset.name }}</h2>

      <HeaderInfo label="Dimensions" :value="dimensions" v-if="isImage" />
      <HeaderInfo label="Size" :value="size" />
      <HeaderInfo label="Last modified" :value="lastModified" />

      <div class="flex-auto"></div>

      <button
        @click.prevent="copyUrl()"
        type="button"
        title="Copy asset link"
        class="copy-btn btn btn-circle mr-2"
      >
        <Icon v-if="isCopied" icon="ok" class="copied-icon" />
        <Icon v-else icon="copy" />
      </button>

      <a :href="asset.location" title="Open" class="btn btn-circle mr-2" target="_blank">
        <Icon icon="open" />
      </a>

      <a :href="downloadUrl" :download="asset.name" class="btn btn-circle">
        <Icon icon="download" />
      </a>

      <div class="am-header-separator"></div>

      <button @click="$emit('close')" type="button" title="Close" class="btn btn-circle">
        <Icon icon="close" />
      </button>
    </div>

    <div class="am-preview-content">
      <div class="am-preview-asset">
        <div class="am-preview-image">
          <div class="am-preview-image-wrapper">
            <ImgixImage :image="asset" :height="800" :width="800" fit="max" v-if="isImage" />
            <FileIcon :asset="asset" :size="180" v-if="!isImage" />
          </div>
        </div>
      </div>

      <div class="am-preview-form">
        <form @submit.prevent="update">
          <div class="field">
            <label class="field-label" for="asset-title">Title</label>
            <span class="field-hint">The display name of the asset</span>
            <input type="text" v-model="title" class="field-control" id="asset-title" />
          </div>

          <div class="field">
            <label class="field-label" for="asset-alt">Alt Text</label>
            <span class="field-hint">An alternative name for accessibility</span>
            <input type="text" v-model="alt" class="field-control" id="asset-alt" />
          </div>

          <div class="am-form-actions">
            <button type="submit" class="btn btn-primary"><Icon icon="ok" />Save</button>

            <button @click="destroy" type="button" class="btn btn-danger">
              <Icon icon="delete" />Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'timeago.js';
import Icon from '@admin/components/Icon';
import store from '../store';
import HeaderInfo from './HeaderInfo';
import ImgixImage from './ImgixImage';
import FileIcon from './FileIcon';
import humanSize from '../helpers/human-size';
import isImage from '../helpers/is-image';
import imgixUrl from '../helpers/imgix-url';

export default {
  name: 'Preview',
  components: { HeaderInfo, ImgixImage, Icon, FileIcon },

  props: {
    asset: Object
  },

  data() {
    return {
      title: this.asset.title,
      alt: this.asset.alt,
      isCopied: false
    };
  },

  computed: {
    size() {
      return humanSize(this.asset.size);
    },

    lastModified() {
      return format(this.asset.updated_at);
    },

    isImage() {
      return isImage(this.asset);
    },

    dimensions() {
      return this.asset.meta.width + ' x ' + this.asset.meta.height;
    },

    downloadUrl() {
      return imgixUrl(this.asset.path, { dl: this.asset.name });
    }
  },

  methods: {
    async update() {
      let asset = await store.update(this.asset.id, {
        title: this.title,
        alt: this.alt
      });

      this.$emit('select', asset);
    },

    async copyUrl() {
      await navigator.clipboard.writeText(this.asset.location);

      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    },

    destroy() {
      store.destroy(this.asset.id);
      this.$emit('select', null);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/hex/variables.scss';

.copied-icon {
  color: $success;
}
</style>
