const COLORS = {};
COLORS.default = ['#0252cc', '#0065FF', '#2684FF', '#4C9AFF', '#B3D4FF', '#B4C3DB'];
COLORS.reversed = [...COLORS.default].reverse();
COLORS.red = ['#ff0000', '#00ff00', '#0000ff'];

function getColors(colors) {
  return typeof COLORS[colors] !== 'undefined' ? COLORS[colors] : colors;
}

function getTextStyles(overwrites = {}) {
  return {
    fontName:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 12,
    color: '#42526e',
    ...overwrites
  };
}

export default function(overwrites = {}, { type = 'bar', colors = 'default' }) {
  const base = {
    pieHole: 0.3,
    areaOpacity: 0.1,
    height: 240,
    width: '100%',
    pointSize: 5,
    chartArea: { width: '100%' },
    animation: { duration: 300, startup: true, easing: 'out' },
    hAxis: {
      textStyle: getTextStyles(),
      maxTextLines: 1,
      gridlines: {
        minSpacing: 100
      }
    },
    vAxis: {
      textStyle: getTextStyles(),
      textPosition: 'in',
      gridlines: { color: '#ebecf0', minSpacing: 50 }
    },
    legend: {
      textStyle: getTextStyles(),
      position: 'top'
    },
    tooltip: { textStyle: getTextStyles() }
  };

  if (type.toLowerCase() === 'geo') {
    base.colorAxis = {
      colors: getColors(colors)
    };
  } else {
    base.colors = getColors(colors);
  }

  return { ...base, ...overwrites };
}
