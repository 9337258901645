<template>
  <div class="asset-input">
    <div class="asset-input-preview-list">
      <PreviewItem
        v-for="asset in assets"
        :key="asset.id"
        :asset="asset"
        :canRemove="true"
        @edit="selectedAssetForPreview = asset"
        @remove="onDeselect(asset)"
      />
    </div>

    <div class="asset-input-controls">
      <button type="button" class="btn btn-sm" @click.stop="openList">
        <Icon icon="folder" /> Browse Assets
      </button>

      <Uploader @upload="onSelect($event)" :multiple="true" class="btn-sm" />
    </div>

    <Modal v-if="isListVisible" @close="isListVisible = false">
      <List
        :selected="assets"
        :multiple="true"
        @select="onSelect"
        @insert="isListVisible = false"
        @close="isListVisible = false"
        @cancel="rollbackAndCloseList"
      />
    </Modal>

    <Modal v-if="selectedAssetForPreview" @close="selectedAssetForPreview = null">
      <Preview
        :asset="selectedAssetForPreview"
        @select="onUpdate"
        @close="selectedAssetForPreview = null"
      />
    </Modal>

    <input
      v-for="asset in assets"
      :key="asset.id"
      type="text"
      :name="name"
      :value="asset.id"
      style="display: none;"
    />
  </div>
</template>

<script>
import Icon from '@admin/components/Icon';
import List from './components/List';
import Modal from './components/Modal';
import Preview from './components/Preview';
import PreviewItem from './components/PreviewItem';
import Uploader from './components/Uploader';

export default {
  name: 'AssetInput',
  components: { List, Preview, PreviewItem, Icon, Modal, Uploader },

  props: {
    initialAssets: { required: false, type: Array, default: () => [] },
    name: String
  },

  data() {
    return {
      isListVisible: false,
      selectedAssetForPreview: false,
      assets: [...this.initialAssets]
    };
  },

  methods: {
    onDeselect(asset) {
      this.assets = this.assets.filter((a) => a.id !== asset.id);
    },

    onSelect(asset) {
      if (this.assets.includes(asset)) {
        this.onDeselect(asset);
      } else {
        this.assets = [...this.assets, asset];
      }
    },

    onUpdate(updatedAsset) {
      this.assets = this.assets.map((asset) =>
        updatedAsset.id == asset.id ? updatedAsset : asset
      );
    },

    openList() {
      this._assetsCopy = [...this.assets];
      this.isListVisible = true;
    },

    rollbackAndCloseList() {
      this.assets = this._assetsCopy;
      this.isListVisible = false;
    }
  }
};
</script>
