import { sanitizerRules } from '../common/HasRichContent';

export default class Redactor {
  static get toolbox() {
    return {
      title: 'Redactor',
      icon: `
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
          <path d="M20 7v10H4V7h16m0-2H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2z"/>
          <path d="M11 8h2v2h-2zM11 11h2v2h-2zM8 8h2v2H8zM8 11h2v2H8zM5 11h2v2H5zM5 8h2v2H5zM8 14h8v2H8zM14 11h2v2h-2zM14 8h2v2h-2zM17 11h2v2h-2zM17 8h2v2h-2z"/>
        </svg>
      `
    };
  }

  static get sanitize() {
    return {
      html: sanitizerRules
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  constructor({ api, data }) {
    this.api = api;

    this.data = {
      html: '',
      ...data
    };
  }

  render() {
    let wrapper = document.createElement('div');
    this.textarea = document.createElement('textarea');
    this.textarea.value = this.data.html;

    wrapper.append(this.textarea);

    $R(this.textarea, this.redactorOptions);

    this.stopPropagation(wrapper);

    return wrapper;
  }

  save() {
    return {
      html: this.textarea.value
    };
  }

  validate({ html }) {
    return !!html;
  }

  /**
   * Stops some events from propagating to Editor.JS core
   *
   * @param {HTMLElement} wrapper
   */
  stopPropagation(wrapper) {
    ['paste', 'keydown'].forEach((eventName) => {
      wrapper.addEventListener(eventName, (e) => e.stopPropagation());
    });
  }

  get redactorOptions() {
    return {
      plugins: ['counter'],
      buttons: ['bold', 'italic', 'deleted', 'lists', 'link'],
      minHeight: '72px',
      pasteBlockTags: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ul',
        'ol',
        'li',
        'p',
        'figure',
        'figcaption'
      ],
      toolbarFixedTopOffset: 74
    };
  }
}
