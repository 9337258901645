export default function() {
  document.querySelectorAll('[data-soft-limit').forEach((node) => {
    let input = node.parentElement.querySelector('input');
    let countSpan = node.querySelector('.inline-hint-soft-limit-count');
    let limit = parseInt(node.dataset.softLimit, 10);

    input.addEventListener('input', (e) => {
      countSpan.textContent = input.value.length;

      if (input.value.length > limit) {
        node.classList.add('-over');
      } else {
        node.classList.remove('-over');
      }
    });
  });
}
