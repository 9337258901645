<template>
  <div class="am-file-item">
    <FileIcon :asset="asset" :size="42" />
    <div class="am-file-item-name">{{ asset.name }}</div>
    <div class="am-file-item-info">{{ humanSize(asset.size) }}</div>
  </div>
</template>

<script>
import FileIcon from './FileIcon';
import humanSize from '../helpers/human-size';

export default {
  name: 'FileItem',
  components: { FileIcon },

  props: {
    asset: Object
  },

  methods: {
    humanSize
  }
};
</script>
