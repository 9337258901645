<template>
  <div class="button-editor-wrap">
    <div class="button-editor-inputs">
      <div>
        <label>Button Label</label>
        <input v-model="label" v-once placeholder="Click me..." />
      </div>
      <div>
        <label>Button Link</label>
        <input v-model="link" v-once placeholder="https://www.example.com/" />
      </div>
      <div>
        <label>Button style</label>
        <select v-model="styles" no-select2>
          <option value="btn-primary">Blue</option>
          <option value="btn-danger">Red</option>
        </select>
      </div>
    </div>

    <div class="button-editor-preview">
      <a :href="link" class="btn" :class="styles">{{ label }}</a>
    </div>
  </div>
</template>

<script>
import { HasRichContent } from '../common/HasRichContent';

export default {
  name: 'Button',
  mixins: [HasRichContent],

  data() {
    return {
      label: '',
      link: '',
      styles: 'btn-primary'
    };
  }
};
</script>
