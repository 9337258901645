const CanHandleEscape = {
  mounted: function() {
    document.addEventListener('keydown', this.onEscape);
  },

  beforeDestroy: function() {
    document.removeEventListener('keydown', this.onEscape);
  },

  methods: {
    onEscape: function(e) {
      if (e.key === 'Escape') {
        this.$emit('escape-pressed');
      }
    }
  }
};

export default CanHandleEscape;
