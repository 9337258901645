import { columns, opaque, transparent } from './icons';
import { sanitizerRules } from '../common/HasRichContent';
import BareComponent from './Component';
import Vue from 'vue';

const Component = Vue.extend(BareComponent);

export default class Columns {
  static get toolbox() {
    return { title: 'Columns', icon: columns };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      columns: sanitizerRules
    };
  }

  constructor({ api, data, block, blocks }) {
    this.api = api;

    this.data = {
      columns: [this.column(), this.column()],
      background: 'transparent',
      ...data
    };

    Promise.resolve().then(() => {
      block.stretched = true;
    });
  }

  render() {
    let propsData = { clean: (str) => this.api.sanitizer.clean(str, Columns.sanitize.columns) };
    let data = this.data;

    this.component = new Component({ propsData, data }).$mount();

    return this.component.$el;
  }

  save() {
    return {
      columns: this.component.columns,
      background: this.component.background
    };
  }

  validate({ columns }) {
    return columns.length > 0;
  }

  renderSettings() {
    let settings = [
      { name: 'opaque', icon: opaque },
      { name: 'transparent', icon: transparent }
    ];

    let buttons = [];
    let wrapper = document.createElement('div');
    wrapper.classList.add('cdx-list-settings');

    settings.forEach((option) => {
      let button = this.createSettingsButton({
        icon: option.icon,
        isActive: option.name === this.data.background
      });

      buttons.push(button);
      wrapper.appendChild(button);

      button.addEventListener('click', () => {
        this.component.background = option.name;

        buttons.forEach((node) => {
          node.classList.toggle(this.api.styles.settingsButtonActive, node === button);
        });
      });
    });

    return wrapper;
  }

  createSettingsButton({ icon, isActive }) {
    let button = document.createElement('div');

    button.classList.add(this.api.styles.settingsButton);
    button.classList.toggle(this.api.styles.settingsButtonActive, isActive);
    button.innerHTML = icon;

    return button;
  }

  column() {
    return { title: '', body: '' };
  }
}
