<template>
  <svg width="24" height="24" class="spinner" viewBox="0 0 48 48">
    <circle class="spinner-path" cx="24" cy="24" r="20" fill="none" stroke-width="6" />
  </svg>
</template>

<script>
export default {
  name: 'Spinner'
};
</script>
