<template>
  <div class="am-header-info">
    <div class="am-header-info-label">{{ label }}</div>
    <div class="am-header-info-value">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'HeaderInfo',

  props: {
    label: String,
    value: String
  }
};
</script>
