export const sanitizerRules = {
  h1: {},
  h2: {},
  h3: {},
  h4: {},
  h5: {},
  h6: {},
  br: {},
  ul: {},
  ol: {},
  li: {},
  p: {},
  b: {},
  strong: {},
  i: {},
  blockquote: {},
  img: false,
  figure: {},
  figcaption: {},
  div: false,
  a: { href: true }
};

export const HasRichContent = {
  props: {
    clean: { required: true, type: Function }
  },

  data: () => ({
    recentlyPasted: false,
    version: 1
  }),

  methods: {
    onPaste() {
      this.recentlyPasted = true;
    },

    maintainVersion() {
      if (this.recentlyPasted) {
        this.version++;
        this.recentlyPasted = false;
      }
    }
  }
};
