<template>
  <GChart
    :settings="{ packages: ['corechart', 'table', 'geochart'] }"
    :type="type"
    :data="data"
    :options="chartOptions"
  />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';
import defaultOptions from '../default-options';

export default {
  components: { GChart },

  props: {
    type: { required: true, type: String },
    data: { required: true, type: Array },
    options: { required: false, type: Object, default: () => ({}) },
    colors: { required: false, type: String, default: 'default' }
  },

  computed: {
    chartOptions() {
      return defaultOptions(this.options, { type: this.type, colors: this.colors });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .google-visualization-table-tr-head {
  .google-visualization-table-th {
    background: white;
    padding: 7px 12px;
    text-align: left;
  }

  .google-visualization-table-type-number {
    text-align: right;
  }
}

::v-deep .google-visualization-table-td {
  padding-left: 12px;
  padding-right: 12px;
}
</style>
