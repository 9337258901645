<template>
  <div class="asset-input">
    <div v-if="!asset" class="asset-input-controls">
      <button type="button" class="btn btn-sm" @click.stop="isListVisible = true">
        <Icon icon="folder" />Browse Assets
      </button>

      <Uploader @upload="onSelect($event)" class="btn-sm" />
    </div>

    <div v-if="asset" class="asset-input-preview">
      <slot>
        <AssetItem>
          <ImgixImage v-if="isImage(asset)" :image="asset" :width="width" fit="max" />
          <FileItem v-if="!isImage(asset)" :asset="asset" />
        </AssetItem>
        <p class="field-hint">{{ asset.title }}</p>
      </slot>

      <div class="asset-input-preview-actions">
        <button type="button" class="btn btn-sm btn-circle" @click.stop="isPreviewVisible = true">
          <Icon icon="edit" />
        </button>
        <button type="button" class="btn btn-sm btn-circle" @click.stop="onSelect(null)">
          <Icon icon="close" />
        </button>
      </div>
    </div>

    <Modal v-if="isListVisible" @close="isListVisible = false">
      <List @select="onSelect" @close="isListVisible = false" />
    </Modal>

    <Modal v-if="isPreviewVisible" @close="isPreviewVisible = false">
      <Preview :asset="asset" @select="onSelect" @close="isPreviewVisible = false" />
    </Modal>

    <input type="text" :name="name" :value="asset && asset.id" style="display: none;" />
  </div>
</template>

<script>
import Icon from '@admin/components/Icon';
import List from './components/List';
import Preview from './components/Preview';
import AssetItem from './components/AssetItem';
import Modal from './components/Modal';
import ImgixImage from './components/ImgixImage';
import FileItem from './components/FileItem';
import Uploader from './components/Uploader';
import isImage from './helpers/is-image';

export default {
  name: 'AssetInput',
  components: { List, Preview, AssetItem, Icon, Modal, ImgixImage, FileItem, Uploader },

  props: {
    initialAsset: Object,
    required: Boolean,
    image: [String, Boolean],
    name: String,
    width: { type: Number, default: 300 }
  },

  data() {
    return {
      isListVisible: false,
      isPreviewVisible: false,
      asset: this.initialAsset
    };
  },

  methods: {
    isImage,

    onSelect(asset) {
      this.asset = asset;
      this.isPreviewVisible = false;
      this.isListVisible = false;

      this.$emit('select', asset);

      if (asset === null) {
        this.$emit('clear');
      }
    }
  }
};
</script>
