import axios from '@common/http/axios';
import { debounce } from 'debounce';

export default {
  nextPageURL: null,

  state: {
    isLoading: true,
    term: '',
    assets: []
  },

  async load() {
    this.state.assets = await this.fetch({
      params: {
        term: this.state.term
      }
    });
  },

  async loadMore() {
    if (this.state.isLoading || !this.nextPageURL) return;

    let assets = await this.fetch({ url: this.nextPageURL });
    this.state.assets = this.state.assets.concat(assets);
  },

  async fetch({ url = '/api/assets', params = {} }) {
    this.state.isLoading = true;

    let {
      data: { data, meta }
    } = await axios.get(url, { params });

    this.state.isLoading = false;
    this.nextPageURL = meta.next;

    return data;
  },

  filter(term) {
    this.state.term = term;
    this.state.isLoading = true;
    this.debounceLoad();
  },

  debounceLoad: debounce(function() {
    this.load();
  }, 200),

  async create(asset) {
    let {
      data: { data }
    } = await axios.post('/api/assets', { asset });

    this.state.assets.unshift(data);

    return data;
  },

  async update(id, attrs) {
    let {
      data: { data }
    } = await axios.patch(`/api/assets/${id}`, attrs);

    this.state.assets = this.state.assets.map((asset) => (asset.id === data.id ? data : asset));

    return data;
  },

  destroy(id) {
    axios.delete(`/api/assets/${id}`);

    this.state.assets = this.state.assets.filter((asset) => id !== asset.id);
  },

  hasMore() {
    return !!this.nextPageURL;
  }
};
