(function($R) {
  $R.add('plugin', 'counter', {
    translations: {
      en: {
        words: 'words',
        chars: 'chars'
      }
    },

    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.utils = app.utils;
      this.editor = app.editor;
      this.statusbar = app.statusbar;
    },

    // public
    start() {
      let $editor = this.editor.getElement();
      $editor.on('keyup.redactor-plugin-counter', this.count.bind(this));

      this.count();
    },

    stop() {
      let $editor = this.editor.getElement();
      $editor.off('.redactor-plugin-counter');

      this.statusbar.remove('words');
      this.statusbar.remove('chars');
    },

    count() {
      let words = 0;
      let characters = 0;
      let spaces = 0;
      let $editor = this.editor.getElement();
      let html = $editor.html();

      html = this._clean(html);

      if (html !== '') {
        let arrWords = html.split(/\s+/);
        let arrSpaces = html.match(/\s/g);

        words = arrWords ? arrWords.length : 0;
        spaces = arrSpaces ? arrSpaces.length : 0;

        characters = html.length;
      }

      let data = { words: words, characters: characters, spaces: spaces };

      // callback
      this.app.broadcast('counter', data);

      let chars = data.characters;
      let maxLength = this.app.rootElement.getAttribute('maxlength');

      if (maxLength) {
        maxLength = parseInt(maxLength, 10);

        chars = `
          <span class="${chars > maxLength ? 'invalid' : ''}">${chars}</span>/${maxLength}
        `;
      }

      // statusbar
      this.statusbar.add('chars', this.lang.get('chars') + ': ' + chars);
      this.statusbar.add('words', this.lang.get('words') + ': ' + data.words);
    },

    // private
    _clean(html) {
      html = html.replace(/<\/(.*?)>/gi, ' ');
      html = html.replace(/<(.*?)>/gi, '');
      html = html.replace(/\t/gi, '');
      html = html.replace(/\n/gi, ' ');
      html = html.replace(/\r/gi, ' ');
      html = html.replace(/&nbsp;/g, '1');
      html = html.trim();
      html = this.utils.removeInvisibleChars(html);

      return html;
    }
  });
})(Redactor);
