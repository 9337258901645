<template>
  <portal to="modal">
    <div class="blanket blanket-tinted cp-modal-blanket" @click.self="$emit('close')">
      <div class="cp-modal">
        <div class="cp-modal-header">
          <h2 class="cp-modal-header-title">Preview</h2>

          <div class="flex-auto"></div>

          <a :href="src" title="Open" class="btn btn-circle mr-2" target="_blank">
            <Icon icon="open" />
          </a>

          <div class="cp-modal-header-separator"></div>

          <button @click="$emit('close')" type="button" title="Close" class="btn btn-circle">
            <Icon icon="close" />
          </button>
        </div>

        <slot></slot>
      </div>
    </div>
  </portal>
</template>

<script>
import Icon from '@admin/components/Icon';

export default {
  name: 'Modal',
  components: { Icon },

  props: { src: String },

  beforeMount() {
    document.body.classList.add('has-modal');
  },

  beforeDestroy() {
    document.body.classList.remove('has-modal');
  }
};
</script>

<style lang="scss">
@import '../styles/hex/variables';

.cp-modal {
  display: grid;
  grid-template-rows: 3.75rem 1fr;
  height: calc(100vh - 4rem);
  margin: 2rem;
  box-shadow: $shadow-md;
  border-radius: 0.25rem;
  background-color: $gray-50;
  overflow: hidden;
}

.cp-modal-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: $gray-200;
}

.cp-modal-header-title {
  margin: 0;
  flex: 0 1 auto;
  max-width: 50%;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cp-modal-header-separator {
  height: 2.25rem;
  width: 1px;
  margin: 0 1rem;
  background-color: $gray-300;
}
</style>
