export default class FancyList {
  static get enableLineBreaks() {
    return true;
  }

  static get toolbox() {
    return {
      icon:
        '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>',
      title: 'Fancy List'
    };
  }

  constructor({ data, api }) {
    this.api = api;

    this.data = {
      title: '',
      items: [],
      ...data
    };
  }

  render() {
    this.list = this._make('ul', [this.CSS.baseBlock, this.CSS.wrapper], {
      contentEditable: true
    });

    // fill with data
    if (this.data.items.length) {
      this.data.items.forEach((item) => {
        this.list.appendChild(
          this._make('li', this.CSS.item, {
            innerHTML: item
          })
        );
      });
    } else {
      this.list.appendChild(this._make('li', this.CSS.item));
    }

    // detect keydown on the last item to escape List
    this.list.addEventListener(
      'keydown',
      (event) => {
        let [ENTER, BACKSPACE] = [13, 8]; // key codes

        switch (event.keyCode) {
          case ENTER:
            this.getOutOfList(event);
            break;
          case BACKSPACE:
            this.backspace(event);
            break;
        }
      },
      false
    );

    this.list.addEventListener('paste', (e) => {
      e.preventDefault();
      e.stopPropagation();

      let content = e.clipboardData.getData('text/plain');
      let items = content.split('\n').filter((str) => str.length > 0);

      items.forEach((item) => {
        this.list.appendChild(
          this._make('li', this.CSS.item, {
            innerHTML: item
          })
        );
      });

      this.data.items = this.data.items.concat(items);
    });

    this.title = this._make('h2', ['fancy-list-title', 'editable'], {
      contentEditable: true
    });
    this.title.setAttribute('data-placeholder', 'Write a title...');
    this.title.innerHTML = this.data.title;
    let titleWrapper = this._make('div');
    titleWrapper.appendChild(this.title);

    let listWrapper = this._make('div');
    listWrapper.appendChild(this.list);

    this.wrapper = this._make('div', 'fancy-list-wrapper');
    this.wrapper.appendChild(titleWrapper);
    this.wrapper.appendChild(listWrapper);

    return this.wrapper;
  }

  save() {
    return {
      title: this.title.innerHTML,
      items: this.items
    };
  }

  get items() {
    return Array.from(this.list.querySelectorAll('li'))
      .filter((node) => node.textContent.trim().length > 0)
      .map((node) => node.innerHTML);
  }

  static get sanitize() {
    return {
      title: {},
      items: {
        br: true
      }
    };
  }

  get CSS() {
    return {
      baseBlock: this.api.styles.block,
      wrapper: 'cdx-list',
      item: 'cdx-list__item'
    };
  }

  _make(tagName, classNames = null, attributes = {}) {
    let el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (let attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  get currentItem() {
    let currentNode = window.getSelection().anchorNode;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return currentNode.closest(`.${this.CSS.item}`);
  }

  getOutOfList(event) {
    let items = this.list.querySelectorAll('.' + this.CSS.item);

    /**
     * Save the last one.
     */
    if (items.length < 2) {
      return;
    }

    let lastItem = items[items.length - 1];
    let currentItem = this.currentItem;

    //  Prevent Default li generation if item is empty
    if (currentItem === lastItem && !lastItem.textContent.trim().length) {
      currentItem.parentElement.removeChild(currentItem);
      this.api.blocks.insertNewBlock();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  backspace(event) {
    let items = this.list.querySelectorAll('.' + this.CSS.item);
    let firstItem = items[0];

    if (!firstItem) {
      return;
    }

    // Save the last one
    if (items.length < 2 && !firstItem.innerHTML.replace('<br>', ' ').trim()) {
      event.preventDefault();
    }
  }
}
