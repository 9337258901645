<template>
  <div class="am-content-empty">
    <img :src="data.src" :alt="data.alt" class="am-content-empty-image" />
    <div class="am-content-empty-primary">{{ data.primary }}</div>
    <div class="am-content-empty-secondary">{{ data.secondary }}</div>
  </div>
</template>

<script>
import noResult from '../assets/no-result.svg';
import noImage from '../assets/no-image.svg';

const MAP = {
  'no-result': {
    src: noResult,
    alt: 'No result',
    primary: 'No results found',
    secondary: 'There are no result matching this search query'
  },

  empty: {
    src: noImage,
    alt: 'Empty',
    primary: 'Asset manager is empty',
    secondary: 'Looks like there are no assets. Start by uploading the first one.'
  }
};

export default {
  props: {
    variant: String
  },

  computed: {
    data() {
      return MAP[this.variant];
    }
  }
};
</script>

<style>
</style>
