<template>
  <div class="cp-root">
    <button @click="isVisible = true" type="button" class="btn">
      Preview
    </button>

    <Modal v-if="isVisible" :src="src" @close="isVisible = false">
      <iframe :src="src" title="Preview" frameborder="0" width="100%" height="100%"></iframe>
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal';

export default {
  name: 'ContentPreview',
  components: { Modal },

  props: {
    src: String
  },

  data() {
    return { isVisible: false };
  }
};
</script>

<style lang="scss">
.cp-root {
  display: inline-block;
}
</style>
