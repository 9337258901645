<template>
  <div class="am-wrapper">
    <div class="am-header">
      <h2 class="am-header-title">Asset Manager</h2>

      <div class="flex-auto"></div>

      <Spinner v-if="state.isLoading" />
      <SearchField :value="state.term" @change="filter" />

      <Uploader
        @upload="$emit('upload', $event)"
        :multiple="true"
        :class="{ 'btn-primary': !multiple }"
      />

      <span v-if="multiple" class="am-header-separator" role="presentation"></span>

      <div v-if="multiple">
        <button type="button" class="btn mr-2" @click="$emit('cancel')">
          <Icon icon="close" /> Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="$emit('insert')">
          <Icon icon="ok" /> Insert ({{ selected.length }})
        </button>
      </div>
    </div>

    <div class="am-content" ref="wrap">
      <div class="am-content-list" ref="list">
        <AssetItem
          v-for="asset in state.assets"
          :key="asset.id"
          :title="asset.name"
          :selected="selectedIds.includes(asset.id)"
          :data-test-asset="asset.id"
          @click="$emit('select', asset)"
        >
          <ImgixImage v-if="isImage(asset)" :image="asset" :height="130" fit="max" />
          <FileItem v-if="!isImage(asset)" :asset="asset" />
        </AssetItem>

        <MugenScroll
          :handler="loadMore"
          :handle-on-mount="false"
          :should-handle="!state.isLoading"
          scroll-container="wrap"
        ></MugenScroll>
      </div>

      <Empty
        v-if="state.assets.length === 0 && !state.isLoading"
        :variant="state.term === '' ? 'empty' : 'no-result'"
      />
    </div>

    <div class="am-footer"></div>
  </div>
</template>

<script>
import store from '../store';
import MugenScroll from 'vue-mugen-scroll';
import Icon from '@admin/components/Icon';
import AssetItem from './AssetItem';
import Empty from './Empty';
import FileItem from './FileItem';
import ImgixImage from './ImgixImage';
import SearchField from './SearchField';
import Spinner from './Spinner';
import Uploader from './Uploader';
import isImage from '../helpers/is-image';

export default {
  name: 'List',

  components: {
    AssetItem,
    Empty,
    FileItem,
    Icon,
    ImgixImage,
    MugenScroll,
    SearchField,
    Spinner,
    Uploader
  },

  props: {
    selected: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false }
  },

  data() {
    return { state: store.state };
  },

  async mounted() {
    await store.load();
    await this.loadMoreToFillScreen();
  },

  computed: {
    selectedIds() {
      return this.selected.map(({ id }) => id);
    }
  },

  methods: {
    isImage,

    async filter(term) {
      await store.filter(term);
      await this.loadMoreToFillScreen();
    },

    loadMore() {
      store.loadMore();
    },

    async loadMoreToFillScreen() {
      let threshold = 0;

      while (
        threshold < 4 &&
        this.$refs.list.offsetHeight + 32 < this.$refs.wrap.offsetHeight &&
        store.hasMore()
      ) {
        await store.loadMore();
        threshold++;
      }
    }
  }
};
</script>
