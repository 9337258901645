<template>
  <div :class="{ 'text-image-wrapper': true, '-left': alignment === 'left' }">
    <div class="text-image-text">
      <!-- v-once is used to handle the Editor inline tools -->
      <h2
        contenteditable="true"
        @input="title = $event.target.innerHTML"
        v-once
        v-html="title"
      ></h2>
      <div
        :key="version"
        contenteditable="true"
        @input="setBody($event.target.innerHTML)"
        v-html="body"
        v-once
        @paste.stop="onPaste"
      ></div>
    </div>

    <div class="text-image-image-wrapper">
      <AssetInput :initial-asset="asset" @select="asset = $event">
        <ImgixImage :image="asset" :width="720" :height="540" fit="fill" class="text-image-image" />
      </AssetInput>
    </div>
  </div>
</template>

<script>
import AssetInput from '../../asset-manager/AssetInput';
import { HasRichContent } from '../common/HasRichContent';
import ImgixImage from '../../asset-manager/components/ImgixImage';

export default {
  name: 'TextImage',
  mixins: [HasRichContent],
  components: { AssetInput, ImgixImage },

  data() {
    return {
      title: '',
      body: '',
      asset: null,
      alignment: 'right'
    };
  },

  methods: {
    setBody(str) {
      this.body = this.clean(str);

      this.maintainVersion();
    }
  }
};
</script>
