<template>
  <div :class="{ 'columns-wrapper': true, '-opaque': background === 'opaque' }">
    <div class="columns" :key="version">
      <div v-for="(column, index) in columns" :key="index" class="columns-column">
        <h2
          @input="column.title = $event.target.innerHTML"
          v-once
          v-html="column.title"
          class="editable"
          contenteditable="true"
          data-placeholder="Write a title..."
        ></h2>

        <div
          @input="setColumnBody(column, $event.target.innerHTML)"
          v-once
          v-html="column.body"
          class="editable"
          contenteditable="true"
          data-placeholder="Write an annotation..."
          @paste.stop="onPaste"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { HasRichContent } from '../common/HasRichContent';

export default {
  name: 'Columns',
  mixins: [HasRichContent],

  data() {
    return {
      columns: [],
      background: 'transparent'
    };
  },

  methods: {
    setColumnBody(column, str) {
      // Clean the body and set it
      column.body = this.clean(str);

      this.maintainVersion();
    }
  }
};
</script>
