<template>
  <div class="asset-input-preview-item" :test-asset="asset.id">
    <div class="asset-input-preview-item-image-wrapper">
      <ImgixImage
        v-if="isImage(asset)"
        :image="asset"
        :height="32"
        :width="48"
        fit="crop"
        class="asset-input-preview-item-image"
      />
      <FileIcon v-if="!isImage(asset)" :asset="asset" :size="24" />
    </div>

    <div class="asset-input-preview-item-info">
      {{ asset.name }}<br />
      <span class="text-muted text-xxs">{{ humanSize(asset.size) }}</span>
    </div>

    <div>
      <button type="button" class="btn btn-sm btn-subtle btn-circle" @click.stop="$emit('edit')">
        <Icon icon="edit" />
      </button>

      <button
        v-if="canRemove"
        title="Remove"
        type="button"
        class="btn btn-sm btn-subtle btn-circle"
        @click.stop="$emit('remove')"
      >
        <Icon icon="close" />
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@admin/components/Icon';
import ImgixImage from './ImgixImage';
import FileIcon from './FileIcon';
import isImage from '../helpers/is-image';
import humanSize from '../helpers/human-size';

export default {
  name: 'PreviewItem',
  components: { Icon, ImgixImage, FileIcon },

  props: {
    asset: Object,
    canRemove: { type: Boolean, required: false, default: false }
  },

  methods: {
    isImage,
    humanSize
  }
};
</script>

<style></style>
