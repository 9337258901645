/* global $R */
import '@activeadmin/activeadmin';
import '../admin/dashboard/google-analytics';
import '../admin/redactor/redactor';
import '../admin/redactor/counter';
import '../admin/select2';
import '../admin/styles/main.scss';

import Vue from 'vue';
import PortalVue from 'portal-vue';
import { AssetsPreview, AssetInput, AssetsInput, AssetManager } from '../admin/asset-manager';
import ContentPreview from '../admin/content-preview';
import initEditor from '../admin/editor';
import initSlugInput from '../admin/slug-input';
import initInputSoftLimit from '../admin/input-soft-limit';

Vue.use(PortalVue);
Vue.component('asset-input', AssetInput);
Vue.component('asset-manager', AssetManager);
Vue.component('assets-input', AssetsInput);
Vue.component('assets-preview', AssetsPreview);
Vue.component('content-preview', ContentPreview);
Vue.component('form-schema-editor', () => import('../admin/form-schema/FormSchemaEditor'));

document.addEventListener('DOMContentLoaded', () => {
  let wrapper = document.querySelector('#wrapper');
  let target = document.createElement('portal-target');

  target.setAttribute('name', 'modal');
  wrapper.prepend(target);

  new Vue({ el: '#wrapper' });

  initEditor();
  initSlugInput();
  initInputSoftLimit();

  $R('textarea.redactor', {
    plugins: ['counter'],
    breakline: true,
    buttons: ['bold', 'italic', 'deleted', 'lists', 'link'],
    minHeight: '72px',
    pasteBlockTags: ['ul', 'ol', 'li', 'p', 'figure', 'figcaption'],
    toolbarFixedTopOffset: 74
  });

  let actions = document.querySelector('.actions');

  if (actions) {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 48) {
        if (!actions.classList.contains('-fixed')) {
          actions.classList.add('-fixed');
        }
      } else {
        actions.classList.remove('-fixed');
      }
    });
  }
});
