<template>
  <div class="asset-input-preview-list">
    <PreviewItem
      v-for="asset in assets"
      :key="asset.id"
      :asset="asset"
      :canRemove="false"
      @edit="selectedAssetForPreview = asset"
    />

    <Modal v-if="selectedAssetForPreview" @close="selectedAssetForPreview = null">
      <Preview
        :asset="selectedAssetForPreview"
        @select="onUpdate"
        @close="selectedAssetForPreview = null"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from './components/Modal';
import Preview from './components/Preview';
import PreviewItem from './components/PreviewItem';

export default {
  name: 'AssetsPreview',
  components: { Preview, PreviewItem, Modal },

  props: {
    initialAssets: { required: false, type: Array, default: () => [] }
  },

  data() {
    return { selectedAssetForPreview: null, assets: [...this.initialAssets] };
  },

  methods: {
    onUpdate(updatedAsset) {
      this.assets = this.assets.map((asset) =>
        updatedAsset.id == asset.id ? updatedAsset : asset
      );
    }
  }
};
</script>
