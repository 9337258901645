<template>
  <div class="panel">
    <h3><slot name="title"></slot></h3>
    <div class="panel_contents">
      <div>
        <div v-if="loading" class="loading" />
        <GoogleChart v-else :type="type" :data="data" :colors="colors" :options="options" />
      </div>
    </div>
  </div>
</template>

<script>
import GoogleChart from './GoogleChart.vue';

const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}/;

const formatDates = (data) =>
  data.map((row) => row.map((col) => (dateRegex.test(col) ? new Date(col) : col)));

export default {
  components: { GoogleChart },

  props: {
    type: { required: true, type: String },
    metrics: { required: false, type: Array },
    dimensions: { required: false, type: Array },
    period: { required: true, type: Object },
    sort: { required: false, type: Array },
    limit: { required: false, type: Number },
    colors: { required: false, type: String, default: 'default' },
    options: { required: false, type: Object, default: () => ({}) }
  },

  watch: {
    period: {
      deep: true,
      handler() {
        this.refetchData();
      }
    }
  },

  data() {
    return {
      loading: true,
      data: []
    };
  },

  mounted() {
    this.loadData();
  },

  computed: {
    query() {
      const query = {
        metrics: this.metrics,
        dimensions: this.dimensions,
        order_bys: this.sort,
        date_ranges: [{ start_date: this.period.start, end_date: this.period.end }]
      };

      if (this.limit) {
        query.limit = this.limit;
      }

      return JSON.stringify(query);
    }
  },

  methods: {
    refetchData() {
      this.loading = true;
      this.data = [];
      this.loadData();
    },

    loadData() {
      fetch(`/api/analytics?query=${this.query}`)
        .then((response) => response.json())
        .then((response) => {
          this.data = formatDates(response);
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  height: 240px;
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 14px;
    opacity: 0.6;
    content: 'Loading, please wait...';
  }
}
</style>
