<template>
  <button
    class="am-asset-item"
    :class="{ '-selected': selected }"
    @click="$emit('click')"
    type="button"
  >
    <Icon v-if="selected" icon="ok" class="am-asset-item-icon" />
    <slot></slot>
  </button>
</template>

<script>
import Icon from '@admin/components/Icon';

export default {
  name: 'AssetItem',
  components: { Icon },

  props: {
    selected: { type: Boolean, default: false }
  }
};
</script>
