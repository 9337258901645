import { sanitizerRules } from '../common/HasRichContent';
import BareComponent from './Component';
import Vue from 'vue';

const Component = Vue.extend(BareComponent);

const iconLeft = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M23 7v2H13V7h10M23 11v2H13v-2h10M21 15v2h-8v-2h8M11 7v10H1V7h10"/>
  </svg>
`;

const iconRight = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M11 7v2H1V7h10M11 11v2H1v-2h10M9 15v2H1v-2h8M23 7v10H13V7h10"/>
  </svg>
`;

export default class TextImage {
  static get toolbox() {
    return { title: 'Text + Image', icon: iconRight };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      title: {},
      body: sanitizerRules
    };
  }

  constructor({ api, data, block }) {
    this.api = api;

    this.data = {
      title: '',
      body: '',
      asset: null,
      alignment: 'right',
      ...data
    };

    Promise.resolve().then(() => {
      block.stretched = true;
    });
  }

  render() {
    let propsData = { clean: (str) => this.api.sanitizer.clean(str, TextImage.sanitize.body) };
    let data = this.data;

    this.component = new Component({ propsData, data }).$mount();

    return this.component.$el;
  }

  save() {
    return {
      title: this.component.title,
      body: this.component.body,
      asset: this.component.asset,
      alignment: this.component.alignment
    };
  }

  validate({ asset }) {
    return !!asset;
  }

  renderSettings() {
    let settings = [
      { name: 'left', icon: iconLeft },
      { name: 'right', icon: iconRight }
    ];

    let buttons = [];
    let wrapper = document.createElement('div');
    wrapper.classList.add('cdx-list-settings');

    settings.forEach((option) => {
      let button = this.createSettingsButton({
        icon: option.icon,
        isActive: option.name === this.data.alignment
      });

      buttons.push(button);
      wrapper.appendChild(button);

      button.addEventListener('click', () => {
        this.component.alignment = option.name;

        buttons.forEach((node) => {
          node.classList.toggle(this.api.styles.settingsButtonActive, node === button);
        });
      });
    });

    return wrapper;
  }

  createSettingsButton({ icon, isActive }) {
    let button = document.createElement('div');

    button.classList.add(this.api.styles.settingsButton);
    button.classList.toggle(this.api.styles.settingsButtonActive, isActive);
    button.innerHTML = icon;

    return button;
  }
}
