import Vue from 'vue';
import BareComponent from './Component';

const Component = Vue.extend(BareComponent);

const icon = `
  <svg width="17" height="15" viewBox="0 0 336 276"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/>
  </svg>
`;

export default class Image {
  constructor({ api, data }) {
    this.api = api;

    this.data = {
      asset: null,
      link: null,
      alignment: 'center',
      wrap: false,
      maxWidth: '100%',
      sampleText: '',
      ...data
    };
  }

  render() {
    let propsData = {
      width: 600,
      notifyForChanges: () => this.api.saver.save()
    };

    let data = {
      asset: this.data.asset,
      link: typeof this.data.link === 'string' ? this.data.link : null,
      alignment: this.data.alignment,
      wrap: this.data.wrap,
      maxWidth: this.data.maxWidth,
      sampleText: this.data.sampleText
    };

    this.inputComponent = new Component({ propsData, data }).$mount();

    return this.inputComponent.$el;
  }

  save() {
    return {
      asset: this.inputComponent.asset,
      link: this.inputComponent.link,
      alignment: this.inputComponent.alignment,
      wrap: this.inputComponent.wrap,
      maxWidth: this.inputComponent.maxWidth,
      sampleText: this.inputComponent.sampleText
    };
  }

  validate({ asset }) {
    return !!asset;
  }

  static get toolbox() {
    return {
      title: 'Image',
      icon
    };
  }
}
