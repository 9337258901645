<template>
  <div class="asset-manager">
    <List v-show="!selectedAsset" @select="onSelect" mode="single" data-test-list />

    <Preview
      v-if="selectedAsset"
      :asset="selectedAsset"
      @select="onClose"
      @close="onClose"
      data-test-preview
    />
  </div>
</template>

<script>
import List from './components/List';
import Preview from './components/Preview';
import CanHandleEscape from '../components/CanHandleEscape.js';

export default {
  name: 'AssetManager',
  components: { List, Preview },
  mixins: [CanHandleEscape],

  data() {
    return {
      selectedAsset: null
    };
  },

  mounted() {
    document.querySelector('#assets a').addEventListener('click', this.navigationCallback);
    this.$on('escape-pressed', () => (this.selectedAsset = null));
  },

  beforeDestroy() {
    document.querySelector('#assets a').removeEventListener('click', this.navigationCallback);
  },

  methods: {
    onSelect(asset) {
      this.selectedAsset = asset;
    },

    onClose() {
      this.selectedAsset = null;
    },

    navigationCallback(e) {
      e.preventDefault();
      e.stopPropagation();

      this.onClose();
    }
  }
};
</script>
