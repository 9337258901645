import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Raw from '@editorjs/raw';
import List from '@editorjs/list';
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import InlineCode from '@editorjs/inline-code';
import Delimiter from '@editorjs/delimiter';
import CodeTool from '@editorjs/code';
import Image from './image';
import TextImage from './text-image';
import Columns from './columns';
import FancyList from './fancy-list';
import Redactor from './redactor';
import Gallery from './gallery';
import Button from './button';
import { Highlight } from './text-highlight/textHighlight';
import { Strikethrough } from './strikethrough/strikethrough';
import { Underline } from './underline/underline';

export default function initEditor() {
  let holder = document.querySelector('.codex-editor-wrapper');

  if (!holder) return;

  let textarea = document.querySelector('.codex-editor-textarea');
  let data = JSON.parse(textarea.value);

  async function onChange() {
    let content = await editor.save();
    textarea.value = JSON.stringify(content);
  }

  let editor = new EditorJS({
    placeholder: 'Start writing or press the Tab key to select a block 🕺',
    holder,
    onChange,

    // prettier-ignore
    tools: {
      header: Header,
      list: { class: List, inlineToolbar: true },
      image: Image,
      'text-image': { class: TextImage, inlineToolbar: true },
      columns: { class: Columns, inlineToolbar: true },
      'fancy-list': { class: FancyList, inlineToolbar: true },
      quote: Quote,
      delimiter: Delimiter,
      code: CodeTool,
      redactor: Redactor,
      raw: Raw,
      gallery: { class: Gallery, inlineToolbar: true },
      button: Button,
      Highlight,
      // Underline,
      InlineCode,
      Strikethrough,

      embed: Embed,


    },

    data
  });
}
