import { sanitizerRules } from '../common/HasRichContent';
import BareComponent from './Component';
import Vue from 'vue';

const Component = Vue.extend(BareComponent);
const icon = `
  <svg
    xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z"/>
  </svg>`;

export default class button {
  static get toolbox() {
    return { title: 'Button', icon };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      label: sanitizerRules,
      link: sanitizerRules
    };
  }

  constructor({ api, data }) {
    this.api = api;

    this.data = {
      label: '',
      link: '',
      styles: 'btn-blue',
      ...data
    };
  }

  render() {
    const propsData = {
      clean: (str) => this.api.sanitizer.clean(str, Button.sanitize.body)
    };
    const data = this.data;

    this.component = new Component({ propsData, data }).$mount();
    return this.component.$el;
  }

  save() {
    return {
      label: this.component.label,
      link: this.component.link,
      styles: this.component.styles
    };
  }

  validate(data) {
    return !!data.label && !!data.link;
  }
}
