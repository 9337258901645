import Vue from 'vue';
import BareComponent from './Component';

const Component = Vue.extend(BareComponent);

const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"/></svg>`;

export default class Gallery {
  static get toolbox() {
    return { title: 'Gallery', icon };
  }

  constructor({ block, data }) {
    this.block = block;
    this.data = { photos: [], ...data };

    this._stretchBlock();
  }

  render() {
    this.component = new Component({ data: this.data }).$mount();

    return this.component.$el;
  }

  save() {
    return { photos: this.component.photos };
  }

  validate({ photos }) {
    return photos.length > 0;
  }

  _stretchBlock() {
    setTimeout(() => {
      if (!this.block) return;

      this.block.stretched = true;
    }, 0);
  }
}
