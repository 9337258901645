<template>
  <label for="am-file-input" class="btn uploader" :class="{ disabled: isUploading }">
    <input
      @change="upload"
      :multiple="multiple"
      :disabled="isUploading"
      type="file"
      name="file"
      id="am-file-input"
      style="display: none;"
    />

    <template v-if="!isUploading"> <Icon icon="upload" /> Upload </template>

    <template v-if="isUploading">
      <Spinner class="btn-spinner" />
      {{ uploadingLabel }}
    </template>
  </label>
</template>

<script>
import Icon from '@admin/components/Icon';
import axios from '@common/http/axios';
import store from '../store';
import Spinner from './Spinner';
import isImage from '../helpers/is-image';
import humanize from 'humanize-string';

export default {
  name: 'Uploader',
  components: { Icon, Spinner },

  props: {
    multiple: { type: Boolean, default: false }
  },

  data() {
    return { uploading: 0 };
  },

  mounted() {
    window.onbeforeunload = () => {
      if (this.isUploading) return 'Are you sure?';
    };
  },

  beforeDestroy() {
    window.onbeforeunload = null;
  },

  computed: {
    isUploading() {
      return this.uploading > 0;
    },

    uploadingLabel() {
      return `Uploading ${this.uploading} file${this.uploading > 1 ? 's' : ''}...`;
    }
  },

  methods: {
    async upload({ target: { files } }) {
      this.uploading = files.length;

      for (let file of files) {
        try {
          await this.uploadFile(file);
        } catch (e) {
          console.log(e);
        }

        this.uploading--;
      }
    },

    async uploadFile(file) {
      let signedData = await this.signUpload(file);
      let { data } = await this.uploadToS3(file, signedData);
      let meta = await this.getMeta(file);
      let extension = file.name.split('.').pop();
      let alt = humanize(file.name.replace(`.${extension}`, ''));

      let asset = await store.create({
        name: file.name,
        path: data.querySelector('Key').textContent,
        location: decodeURIComponent(data.querySelector('Location').textContent),
        content_type: file.type,
        size: file.size,
        extension,
        alt,
        meta
      });

      this.$emit('upload', asset);
    },

    async signUpload(file) {
      let { data } = await axios.get('/api/uploads/sign', {
        params: {
          name: file.name,
          size: file.size,
          content_type: file.type
        }
      });

      return data;
    },

    uploadToS3(file, { url, fields }) {
      let formData = this.createFormData({ ...fields, file });

      return axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'document'
      });
    },

    createFormData(fields) {
      let formData = new FormData();

      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      return formData;
    },

    getMeta(file) {
      return isImage(file) ? this.getImageMeta(file) : {};
    },

    getImageMeta(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        let image = new Image();

        reader.onload = () => (image.src = reader.result);

        image.onload = () => {
          resolve({
            width: image.width,
            height: image.height,
            placeholder: this.getImagePlaceholder(image)
          });
        };

        reader.readAsDataURL(file);
      });
    },

    getImagePlaceholder(image) {
      // The image is 4 pixels bigger, so it does not have blurred edges
      let imageSize = 36;
      let canvasSize = 32;

      let height = imageSize / (image.width / image.height);
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');

      canvas.width = canvasSize;
      canvas.height = canvasSize / (image.width / image.height);

      image.width = imageSize;
      image.height = height;

      ctx.filter = 'blur(2px)';
      ctx.drawImage(image, -2, -2, imageSize, height);

      return canvas.toDataURL('image/jpeg', 0.8);
    }
  }
};
</script>
