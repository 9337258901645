<template>
  <div class="image-block">
    <div
      class="image-block--preview"
      :class="classes"
      :style="`grid-template-columns: ${gridTemplate};`"
    >
      <div class="image-block--left-margin">
        Text will wrap around the image and be displayed here...
      </div>
      <div class="image-block--center">
        <AssetInput :initial-asset="asset" :width="width" @select="asset = $event" />
      </div>
      <div class="image-block--right-margin">
        Text will wrap around the image and be displayed here...
      </div>
    </div>

    <div class="image-block--image-link">
      <input v-if="asset" v-model="link" type="text" placeholder="Link image to (optional)" />
    </div>

    <div v-if="asset" class="image-block--control-bar">
      <select v-model="maxWidth" class="image-block--max-w" no-select2>
        <option v-for="(option, i) in widthOptions" :value="option.value" :key="i">
          {{ option.label }}
        </option>
      </select>

      <div v-if="!isFullWidth">
        <div class="image-block--separator"></div>
        <label class="image-block--alignment" title="Align left">
          <input type="radio" name="alignment" v-model="alignment" value="left" />
          <span v-html="icons.leftAlign" />
        </label>
        <label class="image-block--alignment" title="Align center">
          <input type="radio" name="alignment" v-model="alignment" value="center" />
          <span v-html="icons.centerAlign" />
        </label>
        <label class="image-block--alignment" title="Align right">
          <input type="radio" name="alignment" v-model="alignment" value="right" />
          <span v-html="icons.rightAlign" />
        </label>
      </div>

      <div v-if="!isFullWidth && alignment !== 'center'">
        <div class="image-block--separator"></div>
        <label class="image-block--alignment" title="Allow text wrapping around">
          <input type="checkbox" name="wrap" v-model="wrap" />
          <span v-html="icons.wrapAround" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PortalVue from 'portal-vue';
import AssetInput from '../../asset-manager/AssetInput.vue';

Vue.use(PortalVue);

const widthOptions = [
  { value: '16%', label: '1/6 width' },
  { value: '25%', label: '1/4 width' },
  { value: '33%', label: '1/3 width' },
  { value: '50%', label: '1/2 width' },
  { value: '66%', label: '2/3 width' },
  { value: '100%', label: 'Full width' }
];

const icons = {
  leftAlign: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z"/></svg>`,
  rightAlign: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 21h18v-2H3v2zm6-4h12v-2H9v2zm-6-4h18v-2H3v2zm6-4h12V7H9v2zM3 3v2h18V3H3z"/></svg>`,
  centerAlign: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 15v2h10v-2H7zm-4 6h18v-2H3v2zm0-8h18v-2H3v2zm4-6v2h10V7H7zM3 3v2h18V3H3z"/></svg>`,
  wrapAround: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4 19h6v-2H4v2zM20 5H4v2h16V5zm-3 6H4v2h13.25c1.1 0 2 .9 2 2s-.9 2-2 2H15v-2l-3 3 3 3v-2h2c2.21 0 4-1.79 4-4s-1.79-4-4-4z"/></svg>`
};

export default {
  components: { AssetInput },

  props: {
    width: { required: true, type: Number },
    notifyForChanges: { required: true, type: Function }
  },

  watch: {
    link() {
      this.notifyForChanges();
    }
  },

  data() {
    return {
      asset: null,
      link: null,
      alignment: 'center',
      wrap: false,
      maxWidth: '100%',
      widthOptions,
      icons
    };
  },

  computed: {
    classes() {
      // List possible classes so purgeCSS can whitelist them
      // -left -center -right -wrap
      return [
        `-${this.alignment}`,
        this.wrap && !this.isFullWidth ? '-wrap' : '',
        this.isFullWidth ? '-full-w' : ''
      ];
    },

    gridTemplate() {
      const centerCellSize = this.maxWidth;

      if (this.isFullWidth) {
        return centerCellSize;
      }

      switch (this.alignment) {
        case 'left':
          return `${centerCellSize} auto`;
        case 'right':
          return `auto ${centerCellSize}`;
        case 'center':
          return `1fr ${centerCellSize} 1fr`;
        default:
          throw 'Bad alignment';
      }
    },

    isFullWidth() {
      return this.maxWidth === '100%';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/hex/variables';

input[type='text'] {
  margin-top: -0.5rem;
  margin-bottom: 2rem;
}

.image-block--preview {
  display: grid;
  column-gap: 0.5rem;

  .lorem {
    display: none;
    text-align: left;
    color: $gray-500;
  }

  &.-full-w {
    .image-block--left-margin,
    .image-block--right-margin {
      display: none;
    }
  }

  &.-left {
    .image-block--left-margin {
      display: none;
    }

    &.-wrap .image-block--right-margin {
      background: transparent;

      .lorem {
        display: inline-block;
      }
    }
  }

  &.-right {
    .image-block--right-margin {
      display: none;
    }

    &.-wrap .image-block--left-margin {
      background: transparent;

      .lorem {
        display: inline-block;
      }
    }
  }
}

.image-block--left-margin,
.image-block--right-margin {
  text-align: left;
  color: #a5adba;
  padding: 1rem 0.25rem 0.25rem 0.25rem;
}

.image-block--center {
  min-width: 0;

  ::v-deep .asset-input {
    margin: 0 auto;

    .asset-input-preview {
      max-width: 100%;

      .am-asset-item {
        max-width: 100%;
        margin: 0;

        img {
          max-width: 100%;
          // We use important because the ImgixImage component tries to be smart about the height
          height: auto !important;
        }
      }
    }
  }
}

.image-block--image-link {
  padding: 0.375rem calc(0.75rem + 10px) 0.375rem 0.75rem;

  & > input {
    width: 100%;
    max-width: unset !important;
  }
}

.image-block--control-bar {
  display: flex;
  background: $gray-50;
  border-radius: $border-radius;
  padding: 0.375rem 0.75rem;
}

.image-block--max-w {
  max-width: 7rem;
}

.image-block--alignment {
  input {
    display: none;
  }

  span {
    display: inline-block;
    cursor: pointer;
    padding: 0.375rem;
    border-radius: $border-radius;

    &:hover {
      background: #eff2f5;
    }
  }

  input:checked ~ span {
    color: #388ae5;
  }
}

.image-block--separator {
  border-left: 1px solid $gray-300;
  margin: 0 1rem;
}
</style>
