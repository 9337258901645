<template>
  <div class="ce-block-gallery">
    <h3>Gallery</h3>

    <draggable v-model="photos" ghostClass="ce-block-photo-ghost">
      <transition-group class="ce-block-gallery-photos">
        <div v-for="photo in photos" :key="photo.id">
          <asset-input :initial-asset="photo.asset" @clear="removePhoto(photo)" />
          <div
            contenteditable
            v-html="photo.caption"
            @input="photo.caption = $event.target.innerHTML"
            data-placeholder="Caption..."
            v-once
            class="ce-block-gallery-caption"
          ></div>
        </div>
      </transition-group>
    </draggable>

    <div class="ce-block-gallery-add">
      <h4>Select an image to add another slide</h4>
      <asset-input @select="onAssetAdded" :key="timesAssetsSelected" />
    </div>
  </div>
</template>

<script>
import AssetInput from '../../asset-manager/AssetInput.vue';
import Draggable from 'vuedraggable';
import { nanoid } from 'nanoid';

export default {
  name: 'Gallery',

  components: { AssetInput, Draggable },

  data() {
    return {
      photos: [],
      timesAssetsSelected: 0
    };
  },

  methods: {
    onAssetAdded(asset) {
      this.photos.push(this.createPhoto(asset));
      this.timesAssetsSelected++;
    },

    removePhoto(photo) {
      this.photos.splice(
        this.photos.findIndex((p) => p.id === photo.id),
        1
      );
    },

    createPhoto(asset) {
      return { id: nanoid(), asset, caption: '' };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/hex/variables';

.ce-block-gallery {
  border-left: 3px solid $gray-300;
  padding-left: 1rem;
}

.ce-block-photo-ghost {
  opacity: 0.5;
}

.ce-block-gallery-caption {
  padding: 0.25rem 0.5rem;

  &:empty:not(:focus):before {
    content: attr(data-placeholder);
    color: $gray-400;
    font-style: italic;
  }
}

.ce-block-gallery-photos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  ::v-deep.asset-input-preview {
    margin: 0;
    width: 100%;

    .am-asset-item {
      height: 200px;
      margin: 0;
      width: 100%;

      img {
        object-fit: cover;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.ce-block-gallery-add {
  margin-top: 2rem;

  h4 {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 0.875rem;
  }
}
</style>
