<template>
  <div>
    <div class="actions">
      <select v-model="period" class="field-control" no-select2>
        <option v-for="(option, i) in options" :value="option" :key="i">
          {{ option.label }}
        </option>
      </select>
    </div>
    <slot :period="period"></slot>
  </div>
</template>

<script>
const options = [
  { label: 'Last Day', start: 'yesterday', end: 'today' },
  { label: 'Last Week', start: '7daysAgo', end: 'today' },
  { label: 'Last Month', start: '30daysAgo', end: 'today' },
  { label: 'Last 3 Months', start: '90daysAgo', end: 'today' },
  { label: 'Last Year', start: '365daysAgo', end: 'today' }
];

export default {
  data() {
    return {
      period: options[2]
    };
  },

  computed: {
    options() {
      return options;
    }
  }
};
</script>

<style scoped lang="scss">
select {
  width: 10rem;
}
</style>
