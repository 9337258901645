<template>
  <portal to="modal">
    <div class="blanket blanket-tinted modal-blanket" @click.self="$emit('close')">
      <div class="modal asset-manager-modal">
        <slot></slot>
      </div>
    </div>
  </portal>
</template>

<script>
import CanHandleEscape from '../../components/CanHandleEscape.js';

export default {
  name: 'Modal',
  mixins: [CanHandleEscape],

  beforeMount() {
    document.body.classList.add('has-modal');
  },

  mounted() {
    this.$on('escape-pressed', () => this.$emit('close'));
  },

  beforeDestroy() {
    document.body.classList.remove('has-modal');
  }
};
</script>
