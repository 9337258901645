import slugify from '@sindresorhus/slugify';

export default function initSlugInput() {
  document.querySelectorAll('[data-slug-source]').forEach((input) => {
    let source = document.querySelector(input.dataset.slugSource);

    if (!source) return;

    source.addEventListener('input', (e) => {
      input.value = slugify(e.target.value);
    });
  });
}
