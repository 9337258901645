import $ from 'jquery';
import 'select2/dist/js/select2';

$(function() {
  document.querySelectorAll('select:not([no-select2])').forEach(function(select) {
    let options = {
      allowClear: select.options[0] && select.options[0].value === '',
      placeholder: 'Select an option...'
    };

    $(select).select2(options);
  });
});
